import React from "react";
import Header from "../../components/header";
import Navication from "../../components/nav";
import "./index.css";

const User = () => {
  return (
    <div className="charge">
      <Header />
      <Navication />
    </div>
  )
}

export default User;